import { useRef, useState, FC, useContext, useEffect } from "react";
import "../styles/Settings.css";
import { LanguageContext } from "../hooks/LanguageContext";
import { useTranslation } from "react-i18next";
import { getBotById } from "../apis/settingApi";
import BotsList from "../components/bots-list";
import ShareLinkContainer from "../components/share-link";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../components/header";
import CreateBotForm from "../components/create-bot-form";
import { ErrorCodes } from "../utils/errorCodes";

interface Bot {
  id: string;
  targetAge: string;
  language: string;
  avatarName: string;
  avatarType: string;
  code: string;
  azureVoiceType: string;
  topic: string;
}

interface IntegrationPageProps {
  onLogout: () => void;
  idToken: string;
  userLink: string;
}

const SettingsPage: FC<IntegrationPageProps> = ({
  onLogout,
  idToken,
  userLink,
}) => {
  const { language } = useContext(LanguageContext);
  const { t, i18n } = useTranslation();
  const [age, setAge] = useState<string>("");
  const [avatarName, setAvatarName] = useState<string>("");
  const [avatarType, setAvatarType] = useState<string>("");
  const [chatbotId, setChatbotId] = useState<string>("");
  const [chatbotLink, setChatbotLink] = useState<string>(userLink || "");
  const [code, setCode] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedTutor, setSelectedTutor] = useState<string>("");
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [history, setHistory] = useState<boolean[]>([]);
  const [azureVoiceType, setAvatarVoice] = useState<string>("");
  const [topic, setTopic] = useState<string>("");

  const shareSectionRef = useRef<HTMLDivElement>(null);

  const getErrorMessage = (code: ErrorCodes) => {
    return t(code?.toString(), { ns: "errorMessages" });
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  useEffect(() => {
    if (chatbotId?.length > 0 && shareSectionRef.current) {
      shareSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatbotId]);

  const handleTutorSelect = async (bot: Bot) => {
    setSelectedTutor(bot.id);
    setAvatarName(bot.avatarName);
    setTopic(bot.topic);
    setCode(bot.code);
    setAvatarVoice(bot.azureVoiceType);
    try {
      const botData = await getBotById(idToken, bot.id);
      setChatbotLink(botData.link);
    } catch (error: any) {
      const errorCode = error.response?.status as ErrorCodes;
      const errorMessage =
        getErrorMessage(errorCode) || error.message || t("An error occurred");
      toast.error(errorMessage, {
        autoClose: 3000,
        position: "bottom-center",
      });
    }
  };

  const handleEditBot = (bot: Bot) => {
    setAge(bot.targetAge);
    setAvatarName(bot.avatarName);
    setAvatarType(bot.avatarType);
    setAvatarVoice(bot.azureVoiceType);
    setCode(bot.code);
    setChatbotId(bot.id);
    setTopic(bot.topic);
    setIsEdit(true);
    setShowSettings(true);
    setHistory((prev) => [...prev, false]);
  };

  const handleBack = () => {
    if (history.length > 0) {
      const previousState = history[history.length - 1];
      setShowSettings(previousState);
      setHistory((prev) => prev.slice(0, -1));
      setChatbotLink(userLink || "");
    }
  };

  const handleBotCreatedOrUpdated = (data: {
    chatbotId: string;
    link: string;
    azureVoiceType: string;
  }) => {
    setChatbotId(data.chatbotId);
    setChatbotLink(data.link);
    setAvatarVoice(data.azureVoiceType);
    setShowSettings(true);
    setHistory((prev) => [...prev, false]);
  };

  return (
    <div className="settingsComponent">
      <div className="headerContainer">
        <Header onLogout={onLogout} showLogoutBtn={true} />
        {history.length > 0 && (
          <button className="backButton" onClick={handleBack}>
            {t("back")}
          </button>
        )}
      </div>
      <h1 className={showSettings ? "settingsTitle" : "editBotTitle"}>
        {showSettings ? t("settings") : t("linkTitle")}
      </h1>
      {!showSettings && (
        <>
          <ShareLinkContainer
            shareSectionRef={shareSectionRef}
            chatbotLink={chatbotLink}
            avatarName={avatarName}
            avatarCode={code}
            isNewBot={true}
          />
          <h1 className={"editBotTitle"}>{t("botsListTitle")}</h1>
        </>
      )}
      {!showSettings ? (
        <BotsList
          selectedTutor={selectedTutor}
          handleTutorSelect={handleTutorSelect}
          handleEditBot={handleEditBot}
          setChatbotLink={setChatbotLink}
          handleCreateTutor={() => {
            setAge("");
            setAvatarName("");
            setCode("");
            setChatbotId("");
            setSelectedTutor("");
            setAvatarVoice("");
            setIsEdit(false);
            setChatbotLink("");
            setShowSettings(true);
            setHistory((prev) => [...prev, false]);
          }}
          token={idToken}
        />
      ) : (
        <CreateBotForm
          idToken={idToken}
          language={language}
          isEdit={isEdit}
          initialAge={age}
          initialTopic={topic || ""}
          initialAvatarType={avatarType || ""}
          initialPassword={code}
          chatbotId={chatbotId}
          onBotCreatedOrUpdated={handleBotCreatedOrUpdated}
          onBack={handleBack}
          initialAvatarVoice={azureVoiceType}
          initialAvatarName={avatarName || ""}
        />
      )}
      {showSettings && chatbotLink && chatbotLink.length > 0 && (
        <ShareLinkContainer
          shareSectionRef={shareSectionRef}
          chatbotLink={chatbotLink}
          avatarName={avatarName}
          avatarCode={code}
          isNewBot={false}
        />
      )}
    </div>
  );
};

export default SettingsPage;
