import Lottie, { LottieRefCurrentProps } from "lottie-react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import owl from "../assets/owl.json";
import dog from "../assets/dog.json";
import cat from "../assets/cat.json";
import dino from "../assets/dino.json";
import loadingAnimation from "../assets/loading.json";
import "../styles/ChatComponent.css";
import { FaArrowUp } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { startChatSession, sendChatMessage } from "../apis/chatApi";
import { ToastContainer, toast } from "react-toastify";
import { Avatars } from "../utils/enums";

export interface UserSession {
  conversationId: string;
  token: string;
  expires_in: number;
}

interface Message {
  content: string;
  followUps: string[];
  sessionId?: string;
  avatarName: string;
  avatarType: string;
  audioUrl?: string;
}

const getAnimationData = (avatarType: string) => {
  switch (avatarType) {
    case Avatars.Owl:
      return owl;
    case Avatars.Dog:
      return dog;
    case Avatars.cat:
      return cat;
    case Avatars.dino:
      return dino;
    default:
      return owl;
  }
};

function ChatBot() {
  const ref = useRef<HTMLDivElement>(null);
  const lastMessageRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [sessionId, setSessionId] = useState<string>("");
  const [avatarName, setAvatarName] = useState<string>("");
  const [avatarType, setAvatarType] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState<number>(-1);
  const [password, setPassword] = useState<string>("");
  const [isPasswordEntered, setIsPasswordEntered] = useState<boolean>(false);
  const [playingMessageIndex, setPlayingMessageIndex] = useState<number>(-1);
  const passwordRefs = useRef<(HTMLInputElement | null)[]>([]);
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);
  const { t, i18n } = useTranslation();
  const useid = new URLSearchParams(window.location.search).get("userId") || "";
  const audioRefs = useRef<HTMLAudioElement[]>([]);
  const lang = new URLSearchParams(window.location.search).get("lang") || "";

  useEffect(() => {
    i18n.changeLanguage(lang || "de");
  }, [lang, i18n]);

  const handlePasswordChange =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.replace(/[^0-9]/g, "");
      if (value.length <= 1) {
        setPassword(
          (prev) => prev.slice(0, index) + value + prev.slice(index + 1)
        );
        if (value && passwordRefs.current[index + 1]) {
          passwordRefs.current[index + 1]?.focus();
        }
      }
    };

  const handleEnterChatbot = async () => {
    setLoading(true);
    try {
      const response = await startChatSession(useid, password);
      await handleStreamResponse(response);
      setIsPasswordEntered(true);
    } catch (error) {
      setLoading(false);
      let msg = "An unknown error occurred.";
      if (error instanceof Error) {
        try {
          const errorObj = JSON.parse(error.message.split("response: ")[1]);
          msg = errorObj.error;
        } catch (e) {
          msg = error.message;
        }
      }
      toast.error(msg, {
        autoClose: 2000,
        position: "bottom-center",
      });
    }
  };

  const handleFollowUpClick = async (topic: string) => {
    pauseAllAudio();
    setLoading(true);
    try {
      const response = await sendChatMessage(sessionId, topic);
      await handleStreamResponse(response);
    } catch (error) {
      setLoading(false);
    }
  };

  const setAudioRef = (element: HTMLAudioElement | null, index: number) => {
    if (element) {
      audioRefs.current[index] = element;
    }
  };

  const pauseAllAudio = () => {
    audioRefs.current.forEach((audio) => {
      audio.pause();
    });
    if (lottieRef.current) {
      lottieRef.current.stop();
    }
    setPlayingMessageIndex(-1);
  };

  const handleStreamResponse = async (response: Response) => {
    try {
      const responseData = await response.json();
      const {
        content,
        followUps,
        sessionId,
        avatarName,
        avatarType,
        audio: base64Audio,
      } = responseData;

      const audioUrl = base64Audio
        ? decodeBase64ToAudioUrl(base64Audio)
        : undefined;

      const currentMessage: Partial<Message> = {
        content: content.replace(/\n/g, "\\n"),
        followUps,
        sessionId,
        avatarName,
        avatarType,
        audioUrl,
      };

      if (sessionId) {
        setSessionId(sessionId);
      }
      if (avatarName) {
        setAvatarName(avatarName);
      }
      if (avatarType) {
        setAvatarType(avatarType);
      }
      if (Object.keys(currentMessage).length > 0) {
        setMessages((prevMessages) => [
          ...prevMessages,
          currentMessage as Message,
        ]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error sending chat message", error);
      toast.error(t("errorOccurred"), {
        autoClose: 2000,
        position: "bottom-center",
      });
    }
  };

  const decodeBase64ToAudioUrl = (base64Audio: string): string => {
    const binaryString = atob(base64Audio);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const audioBlob = new Blob([bytes.buffer], { type: "audio/mpeg" });
    return URL.createObjectURL(audioBlob);
  };

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setCurrentMessageIndex(messages.length - 1);
    }
  }, [messages]);

  const scrollToPreviousMessage = () => {
    if (currentMessageIndex > 0) {
      const previousMessageElement = document.querySelectorAll(
        ".message-container"
      )[currentMessageIndex - 1] as HTMLElement;
      if (previousMessageElement) {
        previousMessageElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        setCurrentMessageIndex(currentMessageIndex - 1);
      }
    }
  };

  const handleAudioPlay = (index: number) => {
    setPlayingMessageIndex(index);
    if (lottieRef.current) {
      lottieRef.current.goToAndPlay(0);
    }
  };

  const handleAudioPause = () => {
    setPlayingMessageIndex(-1);
    if (lottieRef.current) {
      lottieRef.current.stop();
    }
  };

  if (!isPasswordEntered) {
    return (
      <div className="chatbot-container">
        <ToastContainer />
        <div className="passwordSettings">
          <label>{t("enterCode")}</label>
          <div className="passwordInputContainer">
            {[...Array(4)].map((_, i) => (
              <input
                key={i}
                type="text"
                maxLength={1}
                value={password[i] || ""}
                onChange={handlePasswordChange(i)}
                ref={(el) => (passwordRefs.current[i] = el)}
                className="passwordInput"
                disabled={loading}
              />
            ))}
          </div>
          <button
            className="enter-chatbot-button"
            onClick={handleEnterChatbot}
            disabled={password.length !== 4 || loading}
          >
            {loading ? <div className="spinner"></div> : t("enterChatbot")}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="chatbot-container">
      <ToastContainer />
      <h1 className="avatar-name">{avatarName}</h1>
      <div className="chat-container">
        <div className="chat-inner-container">
          {loading && (
            <div className="loading-container">
              <Lottie
                className="lottie"
                lottieRef={lottieRef}
                animationData={loadingAnimation}
                loop={true}
              />
            </div>
          )}
          <div className="chat-messages">
            {messages.map((message, index) => (
              <div
                key={index}
                className="message-container"
                ref={index === messages.length - 1 ? lastMessageRef : null}
              >
                <div className="chat-message-row">
                  <div className="chat-message">
                    <p>
                      {message.content.split("\\n").map((line, i) => (
                        <span key={i}>
                          {line}
                          <br />
                        </span>
                      ))}
                    </p>
                    {message.audioUrl && (
                      <div className="audio-div">
                        <audio
                          ref={(el) => setAudioRef(el, index)}
                          controls
                          controlsList="nodownload noplaybackrate"
                          autoPlay
                          onPlay={() => handleAudioPlay(index)}
                          onPause={handleAudioPause}
                          onEnded={handleAudioPause}
                        >
                          <source src={message.audioUrl} type="audio/mpeg" />
                        </audio>
                      </div>
                    )}
                  </div>
                  {(index === messages.length - 1 ||
                    index === playingMessageIndex) && (
                    <div className={`avatar-${avatarType}`}>
                      <Lottie
                        lottieRef={lottieRef}
                        animationData={getAnimationData(avatarType)}
                        loop={true}
                      />
                    </div>
                  )}
                </div>
                <div className="followup-row">
                  <div className="chat-followups">
                    {message.followUps?.length > 0 &&
                      message.followUps.map((followUp, followUpIndex) => (
                        <div
                          key={followUpIndex}
                          className={`chat-followup followup-${followUpIndex}`}
                          onClick={() => handleFollowUpClick(followUp)}
                        >
                          <p>{followUp}</p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))}
            <div ref={ref} />
          </div>
          {messages.length > 1 && (
            <button
              className="go-back-button"
              onClick={scrollToPreviousMessage}
            >
              {t("goBack")} <FaArrowUp />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChatBot;
