import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAllUsersBots, deleteUserBot } from "../apis/settingApi";
import { FaEdit, FaTrash } from "react-icons/fa";
import "../styles/BotsList.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastMessage } from "./ToastContainer";
import Lottie from "lottie-react";
import owl from "../assets/owl.json";
import dog from "../assets/dog.json";
import cat from "../assets/cat.json";
import dino from "../assets/dino.json";

interface Bot {
  id: string;
  targetAge: string;
  language: string;
  avatarName: string;
  avatarType: string;
  code: string;
  azureVoiceType: string;
  topic: string;
}

interface BotsListProps {
  selectedTutor: string;
  handleTutorSelect: (bot: Bot) => void;
  handleEditBot: (bot: Bot) => void;
  handleCreateTutor: () => void;
  setChatbotLink: (link: string) => void;
  token: string;
}

const getAnimationData = (avatarType: string) => {
  switch (avatarType) {
    case "1":
      return owl;
    case "2":
      return dog;
    case "3":
      return cat;
    case "4":
      return dino;
    default:
      return owl;
  }
};

const BotsList: FC<BotsListProps> = ({
  selectedTutor,
  handleTutorSelect,
  handleEditBot,
  handleCreateTutor,
  token,
}) => {
  const { t } = useTranslation();
  const [bots, setBots] = useState<Bot[]>([]);

  useEffect(() => {
    const fetchBots = async () => {
      try {
        const authToken = token;
        const botsData = await getAllUsersBots(authToken);
        setBots(botsData);
      } catch (error) {
        console.error("Failed to fetch bots", error);
      }
    };

    fetchBots();
  }, [token]);

  const handleDelete = async (botId: string) => {
    try {
      const result = await deleteUserBot(token, botId);
      setBots(bots.filter((bot) => bot.id !== botId));
      toast.success(result.message || "AI Tutor deleted successfully!");
    } catch (error: any) {
      console.error("Failed to delete bot", error);
      toast.error(error.message || "Failed to delete AI Tutor");
    }
  };

  return (
    <div className="initialComponent">
      <div className="chooseBot">
        <button className="create-button" onClick={() => handleCreateTutor()}>
          {t("createNewAITutor")}
        </button>
        <div className="botTableContainer">
          <table className="botTable">
            <thead>
              <tr>
                <th>{t("avatar")}</th>
                <th>{t("avatarName")}</th>
                <th>{t("age")}</th>
                <th>{t("language")}</th>
                <th>{t("code")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {bots.map((bot, index) => (
                <tr
                  key={bot.id}
                  onClick={() => handleTutorSelect(bot)}
                  className={`botRow ${index % 2 === 0 ? "even" : "odd"} ${selectedTutor === bot.id ? "selected" : ""}`}
                >
                  <td>
                    <div className={`${bot.avatarType}Container`}>
                      <Lottie
                        animationData={getAnimationData(bot.avatarType)}
                        loop={false}
                        autoPlay={false}
                      />
                    </div>
                  </td>
                  <td>{bot.avatarName}</td>
                  <td>{bot.targetAge}</td>
                  <td>{bot.language}</td>
                  <td>{bot.code}</td>
                  <td className="actionButtons">
                    <button
                      className="editButton"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditBot(bot);
                      }}
                    >
                      {t("edit")}
                      <FaEdit className="icon" />
                    </button>
                    <button
                      className="deleteButton"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(bot.id);
                      }}
                    >
                      {t("delete")}
                      <FaTrash className="icon" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ToastMessage />
    </div>
  );
};

export default BotsList;
