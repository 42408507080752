import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SettingsPage from "./pages/settings-page";
import ChatBot from "./pages/ChatbotPage";
import { LanguageProvider } from "./hooks/LanguageContext";
import { useState } from "react";
import LoginForm from "./components/LoginForm";
import ResetPasswordPage from "./pages/resetPasswordPage";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [idToken, setIdToken] = useState("");
  const [uid, setUid] = useState("");
  const [link, setLink] = useState("");

  const handleLogin = (token: string, link: string) => {
    setIsAuthenticated(true);
    setIdToken(token);
    setUid(uid);
    setLink(link);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setIdToken("");
    setUid("");
    console.log("User signed out successfully.");
  };

  return (
    <Router>
      <LanguageProvider>
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <SettingsPage
                  onLogout={handleLogout}
                  idToken={idToken}
                  userLink={link}
                />
              ) : (
                <LoginForm onLogin={handleLogin} />
              )
            }
          />
          <Route path="/chatbot-page" element={<ChatBot />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
        </Routes>
      </LanguageProvider>
    </Router>
  );
}

export default App;