import { FC, useEffect, useContext } from "react";
import "../styles/Settings.css";
import "react-toastify/dist/ReactToastify.css";
import { LanguageContext } from "../hooks/LanguageContext";
import { useTranslation } from "react-i18next";

interface LegalDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const LegalDialog: FC<LegalDialogProps> = ({ isOpen, onClose }) => {
  const { t, i18n } = useTranslation();
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="legal-dialog-overlay">
      <div className="legal-dialog">
        <h2 className="bold">{t('legalRightsTitle')}</h2>
        <h3 className="pt-2">{t('legalRightsSubtitle')}</h3>
        <p>{t('legalRights')}</p>
        <button onClick={onClose}>OK</button>
      </div>
    </div>
  );
};

export default LegalDialog;
