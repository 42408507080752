import { baseBackendUrl } from "../utils/baseUrls";

export const registerUser = async (
  email: string,
  password: string,
  name: string
) => {
  const response = await fetch(`${baseBackendUrl}/user/register`, {
    method: "POST",
    headers: {
      "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password, name }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to register user");
  }

  return response.json();
};

export const loginUser = async (email: string, password: string) => {
  const response = await fetch(`${baseBackendUrl}/user/login`, {
    method: "POST",
    headers: {
      "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
      "Content-Type": "application/json",
      "X-Source": window.location.origin,
    },
    body: JSON.stringify({ email, password }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to login");
  }

  return response.json();
};

export const resetPassword = async (email: string) => {
  const response = await fetch(`${baseBackendUrl}/user/request-reset-password`, {
    method: "POST",
    headers: {
      "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
      'X-Source': window.location.origin,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to send password reset email");
  }

  console.log("Password reset email sent successfully.");
};

export const resetPasswordApi = async (token: string, password: string) => {
  const response = await fetch(`${baseBackendUrl}/user/reset-password`, {
    method: "POST",
    headers: {
      "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token, password }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to reset password");
  }

  return response.json();
};
