import React, { useState, useContext, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "../styles/LoginForm.css";
import { registerUser, loginUser, resetPassword } from "../apis/loginApi";
import { ToastContainer, toast } from "react-toastify";
import { LanguageContext } from "../hooks/LanguageContext";
import { useTranslation } from "react-i18next";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Header from "../components/header";
import { ErrorCodes } from "../utils/errorCodes";

interface LoginFormProps {
  onLogin: (idToken: string, link: string) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin }) => {
  const [loading, setLoading] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { language } = useContext(LanguageContext);
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    repeatPassword: "",
  });

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    repeatPassword: false,
  });

  const [errorMessages, setErrorMessages] = useState({
    email: "",
    password: "",
  });

  const getErrorMessage = (code: ErrorCodes) => {
    return t(code?.toString(), { ns: "errorMessages" });
  };

  useEffect(() => {
    i18n.changeLanguage(language);
    const savedEmail = localStorage.getItem("savedEmail");
    const savedPassword = localStorage.getItem("savedPassword");
    if (savedEmail && savedPassword) {
      setFormData((prevData) => ({
        ...prevData,
        email: savedEmail,
        password: savedPassword,
      }));
      setRememberMe(true);
    }
  }, [language, i18n]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "email" && !isEmailValid(value)) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: true }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        [name]: t("invalidEmail"),
      }));
    } else if (name === "password" && !isPasswordValid(value)) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: true }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        [name]: t("invalidPassword"),
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
      setErrorMessages((prevMessages) => ({ ...prevMessages, [name]: "" }));
    }
  };

  const isEmailValid = (email: string) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const isPasswordValid = (password: string) => {
    const pattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
    return pattern.test(password);
  };

  const isFormValid = () => {
    if (showSignUp) {
      return (
        formData.firstName &&
        formData.lastName &&
        formData.email &&
        formData.password &&
        formData.repeatPassword &&
        formData.password === formData.repeatPassword &&
        isPasswordValid(formData.password)
      );
    } else {
      return (
        formData.email &&
        formData.password &&
        isEmailValid(formData.email) &&
        isPasswordValid(formData.password)
      );
    }
  };

  const validateForm = () => {
    const newErrors = {
      firstName: showSignUp && !formData.firstName,
      lastName: showSignUp && !formData.lastName,
      email: !formData.email || !isEmailValid(formData.email),
      password:
        !formData.password ||
        (showSignUp && !isPasswordValid(formData.password)),
      repeatPassword:
        showSignUp && formData.password !== formData.repeatPassword,
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some(Boolean);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true);
    try {
      if (showSignUp) {
        await registerUser(
          formData.email,
          formData.password,
          `${formData.firstName} ${formData.lastName}`
        );
        setLoading(false);
        setShowSignUp(false);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          repeatPassword: "",
        });
        toast.success(t("registrationSuccess"), {
          autoClose: 2000,
          position: "bottom-center",
          style: { backgroundColor: "green", color: "white" },
        });
      } else {
        const response = await loginUser(formData.email, formData.password);
        const idToken = response?.token;
        const userLink = response?.link;

        setLoading(false);
        onLogin(idToken, userLink);

        if (rememberMe) {
          localStorage.setItem("savedEmail", formData.email);
          localStorage.setItem("savedPassword", formData.password);
        } else {
          localStorage.removeItem("savedEmail");
          localStorage.removeItem("savedPassword");
        }
      }
    } catch (error: Error | any) {
      setLoading(false);
      const errorCode = error.response?.status as ErrorCodes;
      const errorMessage =
        getErrorMessage(errorCode) || error.message || t("An error occurred");
      toast.error(errorMessage, {
        autoClose: 3000,
        position: "bottom-center",
      });
    }
  };

  const handleResetPassword = async () => {
    if (!formData.email) {
      setErrors((prevErrors) => ({ ...prevErrors, email: true }));
      return;
    }

    setLoading(true);
    try {
      await resetPassword(formData.email);
      setLoading(false);
      toast.success(t("resetLinkSent"), {
        autoClose: 2000,
        position: "bottom-center",
      });
    } catch (error: Error | any) {
      setLoading(false);
      const errorCode = error.response?.status as ErrorCodes;
      const errorMessage =
        getErrorMessage(errorCode) || error.message || t("An error occurred");
      toast.error(errorMessage, {
        autoClose: 3000,
        position: "bottom-center",
      });
    }
  };

  return (
    <div className="mainContainer">
      <div className="HeaderContainer">
        <Header showLogoutBtn={false} />
      </div>
      <form autoComplete="on" onSubmit={handleSubmit}>
        {!showSignUp ? (
          <div className="login-form-box">
            <h2 className="login-form-title">{t("signIn")}</h2>
            <div className="login-form-field">
              <label className="login-form-label">{t("email")}</label>
              <input
                type="email"
                name="email"
                className="login-form-input"
                placeholder={t("enterEmail")}
                value={formData.email}
                onChange={handleInputChange}
                autoComplete="username"
                required
              />
              {errors.email && (
                <p className="error-text">
                  {errorMessages.email || t("emailRequired")}
                </p>
              )}
            </div>
            <div className="login-form-field">
              <label className="login-form-label">{t("password")}</label>
              <div className="password-input-container">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className="login-form-input"
                  placeholder={t("enterPassword")}
                  value={formData.password}
                  onChange={handleInputChange}
                  autoComplete="current-password"
                  required
                />
                <button
                  type="button"
                  className="password-toggle-button"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              {errors.password && (
                <p className="error-text">
                  {errorMessages.password || t("passwordRequired")}
                </p>
              )}
            </div>
            <div className="login-form-field-remember-me">
              <input
                className="login-form-field-remember-me-checkbox"
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={() => setRememberMe((prev) => !prev)}
              />
              <label htmlFor="rememberMe">{t("rememberMe")}</label>
            </div>
            <button
              type="submit"
              className="login-form-button"
              style={{ display: loading ? "none" : "flex" }}
              disabled={!isFormValid()}
            >
              {loading ? <div className="loader"></div> : t("signIn")}
            </button>
            {loading && (
              <div className="loading-spinner">
                <ClipLoader color="#000000" size={20} />
              </div>
            )}
            <div className="forgot-password">
              <a href="#" onClick={handleResetPassword}>
                {t("forgotPassword")}
              </a>
            </div>
            <p className="forgot-password text-right">
              {t("needAccount")}{" "}
              <a
                href="#"
                onClick={() => {
                  setShowSignUp(true);
                  setFormData({
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    repeatPassword: "",
                  });
                  setErrors({
                    firstName: false,
                    lastName: false,
                    email: false,
                    password: false,
                    repeatPassword: false,
                  });
                }}
              >
                {t("signUp")}
              </a>
            </p>
          </div>
        ) : (
          <div className="login-form-box">
            <h2 className="login-form-title">{t("signUp")}</h2>
            <div className="login-form-field">
              <label className="login-form-label">{t("firstName")}</label>
              <input
                type="text"
                name="firstName"
                className="login-form-input"
                placeholder={t("firstName")}
                value={formData.firstName}
                onChange={handleInputChange}
                autoComplete="given-name"
                required
              />
              {errors.firstName && (
                <p className="error-text">{t("firstNameRequired")}</p>
              )}
            </div>
            <div className="login-form-field">
              <label className="login-form-label">{t("lastName")}</label>
              <input
                type="text"
                name="lastName"
                className="login-form-input"
                placeholder={t("lastName")}
                value={formData.lastName}
                onChange={handleInputChange}
                autoComplete="family-name"
                required
              />
              {errors.lastName && (
                <p className="error-text">{t("lastNameRequired")}</p>
              )}
            </div>
            <div className="login-form-field">
              <label className="login-form-label">{t("email")}</label>
              <input
                type="email"
                name="email"
                className="login-form-input"
                placeholder={t("enterEmail")}
                value={formData.email}
                onChange={handleInputChange}
                autoComplete="email"
                required
              />
              {errors.email && (
                <p className="error-text">
                  {errorMessages.email || t("emailRequired")}
                </p>
              )}
            </div>
            <div className="login-form-field">
              <label className="login-form-label">{t("password")}</label>
              <div className="password-input-container">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className="login-form-input"
                  placeholder={t("enterPassword")}
                  value={formData.password}
                  onChange={handleInputChange}
                  autoComplete="new-password"
                  required
                />
                <button
                  type="button"
                  className="password-toggle-button"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              {errors.password && (
                <p className="error-text">
                  {errorMessages.password || t("passwordRequired")}
                </p>
              )}
              {formData.password && !isPasswordValid(formData.password) && (
                <p className="password-text">{t("passwordCriteria")}</p>
              )}
            </div>
            <div className="login-form-field">
              <label className="login-form-label">{t("repeatPassword")}</label>
              <input
                type="password"
                name="repeatPassword"
                className="login-form-input"
                placeholder={t("repeatPassword")}
                value={formData.repeatPassword}
                onChange={handleInputChange}
                autoComplete="new-password"
                required
              />
              {errors.repeatPassword && (
                <p className="error-text">{t("repeatPasswordRequired")}</p>
              )}
              {formData.password !== formData.repeatPassword && (
                <p className="error-text">{t("passwordsMustMatch")}</p>
              )}
            </div>
            <button
              type="submit"
              className="login-form-button"
              style={{ display: loading ? "none" : "flex" }}
              disabled={!isFormValid()}
            >
              {loading ? <div className="loader"></div> : t("signUp")}
            </button>
            {loading && (
              <div className="loading-spinner">
                <ClipLoader color="#000000" size={20} />
              </div>
            )}
            <p className="forgot-password text-right">
              {t("alreadyRegistered")}{" "}
              <a
                href="#"
                onClick={() => {
                  setShowSignUp(false);
                  setFormData({
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    repeatPassword: "",
                  });
                  setErrors({
                    firstName: false,
                    lastName: false,
                    email: false,
                    password: false,
                    repeatPassword: false,
                  });
                }}
              >
                {t("signIn")}
              </a>
            </p>
          </div>
        )}
      </form>
      <ToastContainer />
    </div>
  );
};

export default LoginForm;
