import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";

export const SUPPORTED_LANGUAGES = ["en", "de"] as const;
export type SupportedLanguage = (typeof SUPPORTED_LANGUAGES)[number];

interface LanguageContextProps {
  language: SupportedLanguage;
  changeLanguage: (lang: SupportedLanguage) => void;
  supportedLanguages: readonly SupportedLanguage[];
}

const defaultLanguage: SupportedLanguage = "en";

export const LanguageContext = createContext<LanguageContextProps>({
  language: defaultLanguage,
  changeLanguage: () => {},
  supportedLanguages: SUPPORTED_LANGUAGES,
});

interface LanguageProviderProps {
  children: ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({
  children,
}) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<SupportedLanguage>(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const langParam = urlParams.get("lang");
    return langParam &&
      SUPPORTED_LANGUAGES.includes(langParam as SupportedLanguage)
      ? (langParam as SupportedLanguage)
      : (i18n.language as SupportedLanguage) || defaultLanguage;
  });

  useEffect(() => {
    if (language !== i18n.language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  const changeLanguage = useCallback((lang: SupportedLanguage) => {
    setLanguage(lang);
  }, []);

  const contextValue = useMemo(
    () => ({
      language,
      changeLanguage,
      supportedLanguages: SUPPORTED_LANGUAGES,
    }),
    [language, changeLanguage]
  );

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};
