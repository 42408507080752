import React from "react";
import LangSwitch from "./LangSwitch";
import { IoLogOut } from "react-icons/io5";
import "../styles/Header.css";

const Header: React.FC<{ onLogout?: () => void; showLogoutBtn?: boolean }> = ({
  onLogout,
  showLogoutBtn,
}) => {
  return (
    <header className="header">
      <div className="header-content">
        <div className="header-actions">
          <LangSwitch locals={["en", "de"]} />
          {showLogoutBtn && (
            <button className="logoutButton" onClick={onLogout}>
              <IoLogOut className="logoutIcon" />
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
