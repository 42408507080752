import React, { useContext } from "react";
import { LanguageContext, SupportedLanguage } from "../hooks/LanguageContext";
import classnames from "classnames";

interface LangSwitchProps {
  locals: SupportedLanguage[];
}

const LangSwitch: React.FC<LangSwitchProps> = ({ locals }) => {
  const { language, changeLanguage } = useContext(LanguageContext);

  return (
    <span>
      {locals.map((local) => (
        <button
          key={local}
          type="button"
          className={classnames(
            "relative inline-flex items-center px-1 py-1 text-base font-bold uppercase rounded-sm",
            language === local
              ? "bg-dark-gray text-light-gray z-10"
              : "bg-light-gray text-dark-gray"
          )}
          onClick={() => changeLanguage(local)}
          disabled={language === local}
        >
          {local}
        </button>
      ))}
    </span>
  );
};

export default LangSwitch;
