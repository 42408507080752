import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsEn from './translations/en/translation.json'; 
import translationsDe from './translations/de/translation.json'; 

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationsEn,
    },
    de: {
      translation: translationsDe,
    },
  },
  lng: 'de',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, 
  },
});

export default i18n;
