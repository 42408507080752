export enum Avatars {
  Owl = "1",
  Dog = "2",
  cat = "3",
  dino = "4",
}

export enum VoiceOptions {
  Amala = "1",
  Maja = "2",
  Gisela = "3",
  Bernd = "4",
  Klaus = "5",
}
