import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPasswordApi } from "../apis/loginApi";
import { ToastContainer, toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../styles/ResetPassword.css";

const ResetPasswordPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") || "";
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    if (!isPasswordValid(password)) {
      toast.error("Password does not meet criteria");
      return;
    }

    setLoading(true);
    try {
      await resetPasswordApi(token, password);
      setLoading(false);
      toast.success("Password reset successfully");
      navigate("/");
    } catch (error: any) {
      setLoading(false);
      toast.error("Failed to reset password");
    }
  };

  const isPasswordValid = (password: string) => {
    const pattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
    return pattern.test(password);
  };

  return (
    <div className="mainContainer">
      <div className="reset-password-box">
        <h2 className="reset-password-title">Reset Password</h2>
        <div className="reset-password-field">
          <label className="reset-password-label">New Password</label>
          <div className="password-input-container">
            <input
              type={showPassword ? "text" : "password"}
              className="reset-password-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              className="password-toggle-button"
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {!isPasswordValid(password) && password && (
            <p className="password-text">
              Password must be at least 8 characters long and include at least one number and one special character.
            </p>
          )}
        </div>
        <div className="reset-password-field">
          <label className="reset-password-label">Confirm Password</label>
          <div className="password-input-container">
            <input
              type={showConfirmPassword ? "text" : "password"}
              className="reset-password-input"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button
              type="button"
              className="password-toggle-button"
              onClick={() => setShowConfirmPassword((prev) => !prev)}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {password !== confirmPassword && confirmPassword && (
            <p className="error-text">Passwords do not match</p>
          )}
        </div>
        <button
          className="reset-password-button"
          onClick={handleResetPassword}
          disabled={loading}
        >
          {loading ? "Resetting..." : "Reset Password"}
        </button>
        <ToastContainer />
      </div>
    </div>
  );
};

export default ResetPasswordPage;
