import { baseBackendUrl } from "../utils/baseUrls";

export const startChatSession = async (userId: string, password: string) => {
  const response = await fetch(`${baseBackendUrl}/chat/start`, {
    method: 'POST',
    headers: {
      'x-api-key': process.env.REACT_APP_BACKEND_API_KEY || '',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId, code: password }),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`HTTP error! status: ${response.status}, response: ${errorText}`);
  }

  return response;
};

export const sendChatMessage = async (sessionId: string, topic: string) => {
  const response = await fetch(`${baseBackendUrl}/chat/message`, {
    method: 'POST',
    headers: {
      'x-api-key': process.env.REACT_APP_BACKEND_API_KEY || '',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sessionId: sessionId,
      topic: topic,
    }),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`HTTP error! status: ${response.status}, response: ${errorText}`);
  }

  return response;
};
