import { baseBackendUrl } from "../utils/baseUrls";

export const createTutor = async (
  age: string,
  language: string,
  topic: string,
  idToken: string,
  password: string,
  avatarName: string,
  avatarType: string,
  azureVoiceType: string
): Promise<any> => {
  const requestBody = {
    targetAge: age,
    language: language,
    topic: topic,
    code: password,
    avatarName: avatarName,
    avatarType: avatarType,
    azureVoiceType: azureVoiceType,
  };

  const response = await fetch(`${baseBackendUrl}/chatbot/create`, {
    method: "POST",
    headers: {
      "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
      "Content-Type": "application/json",
      Authorization: `${idToken}`,
      "X-Source": window.location.origin,
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to create AI Tutor");
  }

  return response.json();
};

export const updateTutor = async (
  chatbotId: string,
  idToken: string,
  targetAge?: string | number,
  language?: string,
  topic?: string,
  code?: string,
  avatarName?: string,
  avatarType?: string,
  azureVoiceType?: string
): Promise<any> => {
  const requestBody: any = {
    chatbotId: chatbotId,
  };

  if (targetAge !== undefined) requestBody.targetAge = targetAge;
  if (language) requestBody.language = language;
  if (topic) requestBody.topic = topic;
  if (avatarName) requestBody.avatarName = avatarName;
  if (code) requestBody.code = code;
  if (avatarType) requestBody.avatarType = avatarType;
  if (azureVoiceType) requestBody.azureVoiceType = azureVoiceType;

  console.log("Request Body:", requestBody);

  try {
    const response = await fetch(`${baseBackendUrl}/chatbot/update`, {
      method: "PUT",
      headers: {
        "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
        "Content-Type": "application/json",
        Authorization: idToken || "",
        "X-Source": window.location.origin,
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Failed to update AI Tutor");
    }

    return await response.json();
  } catch (error) {
    console.error("Error updating tutor:", error);
    throw error;
  }
};

export const getAllUsersBots = async (authToken: string) => {
  const response = await fetch(`${baseBackendUrl}/chatbot/get-all`, {
    method: "GET",
    headers: {
      "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
      Authorization: authToken,
      "Content-Type": "application/json",
      "X-Source": window.location.origin,
    },
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(
      `HTTP error! status: ${response.status}, response: ${errorText}`
    );
  }

  return response.json();
};

export const deleteUserBot = async (authToken: string, botId: string) => {
  const requestBody = {
    chatbotId: botId,
  };
  const response = await fetch(`${baseBackendUrl}/chatbot/delete`, {
    method: "DELETE",
    headers: {
      "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
      "Content-Type": "application/json",
      Authorization: authToken,
      "X-Source": window.location.origin,
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to delete AI Tutor");
  }
  return response.json();
};

export const getBotById = async (
  authToken: string,
  botId: string
): Promise<any> => {
  const requestBody = {
    chatbotId: botId,
  };

  const response = await fetch(`${baseBackendUrl}/chatbot/get`, {
    method: "POST",
    headers: {
      "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
      Authorization: authToken,
      "Content-Type": "application/json",
      "X-Source": window.location.origin,
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to fetch AI Tutor");
  }

  return response.json();
};

export const getVoiceListAzure = async (
  authToken: string,
  language: string
): Promise<any> => {
  const requestBody = {
    language: language,
  };

  const response = await fetch(
    `${baseBackendUrl}/chatbot/get-voice-list-azure`,
    {
      method: "POST",
      headers: {
        "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
        Authorization: authToken,
        "Content-Type": "application/json",
        "X-Source": window.location.origin,
      },
      body: JSON.stringify(requestBody),
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to fetch voice list");
  }

  return response.json();
};

export const getSampleVoice = async (
  azureVoiceType: string,
  language: string
): Promise<any> => {
  const requestBody = {
    azureVoiceType: azureVoiceType,
    language: language,
  };

  const response = await fetch(`${baseBackendUrl}/chatbot/get-sample-voice`, {
    method: "POST",
    headers: {
      "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
      "Content-Type": "application/json",
      "X-Source": window.location.origin,
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to fetch sample voice");
  }

  return response.json();
};
