const devHost = process.env.REACT_APP_DEV_HOST;

export const baseBackendUrl = window.location.origin.includes("localhost") || window.location.origin === devHost
  ? process.env.REACT_APP_BACKEND_URL_LOCAL
  : process.env.REACT_APP_BACKEND_URL;


export const hostUrl =  window.location.origin.includes("localhost") || window.location.origin === devHost
  ? process.env.REACT_APP_DEV_HOST
  : process.env.REACT_APP_HOST;


  