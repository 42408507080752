import { useState, ChangeEvent, useCallback, useEffect } from "react";

export const useTopic = (initialTopic: string = "") => {
  const [topic, setTopic] = useState<string>(initialTopic);
  const [customTopic, setCustomTopic] = useState<string>("");

  const predefinedTopics = [
    "Tech: AI",
    "History: The Romans",
    "Geography: Africa",
    "Biology: Bugs",
    "Plastic Pollution",
    "Dinosaurs",
  ];

  useEffect(() => {
    if (initialTopic) {
      setTopic(initialTopic);
      if (!predefinedTopics.includes(initialTopic)) {
        setCustomTopic(initialTopic);
      }
    }
  }, [initialTopic]);

  const handleTopicChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTopic(value);
    if (!predefinedTopics.includes(value)) {
      setCustomTopic(value);
    } else {
      setCustomTopic("");
    }
  };

  const handleCustomTopicChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCustomTopic(value);
    setTopic(value);
  };

  const setInitialTopic = useCallback(
    (newInitialTopic: string) => {
      setTopic(newInitialTopic);
      if (!predefinedTopics.includes(newInitialTopic)) {
        setCustomTopic(newInitialTopic);
      } else {
        setCustomTopic("");
      }
    },
    [predefinedTopics]
  );

  return {
    topic,
    customTopic,
    predefinedTopics,
    handleTopicChange,
    handleCustomTopicChange,
    setInitialTopic,
  };
};
